<template>
  <div>
    <b-modal id="modalNesAnswer"
            size="lg"
            :title="titleModal"
            title-class="centered"
            centered
            @show="showModal"
            hide-footer>
      <form-render :form.sync="form" :fields="fieldsForm" @send="createEditAnswer" :buttonSend="buttonSend" :containerButtonsClass="containerButtonsClass"></form-render>
    </b-modal>
  </div>
</template>

<script>
import { mounted } from 'vue-echarts'
import BaseServices from '@/store/services/index'
export default {
  name: 'modal-new-answer',
  props: {
    countryAnswer: {},
    dataEdit: {},
    updateData: {},
    dataPermission:{}
  },
  watch: {
    dataEdit: {
      handler () {
        if (!!this.dataEdit) {
          this.setValuesEdit()
        } else {
          this.form = {}
          this.titleModal = 'Crear respuesta predeterminada'
          this.form.delivery_type = {
            id: '',
            text: 'Todos'
          }
        }
      },
      deep: true
    },
    countryAnswer: {
      handler () {
        if (this.countryAnswer !== {}) this.country = [...this.countryAnswer]
      },
      deep: true
    },
    dataPermission: {
      handler () {
        if (this.dataPermission !== {}) this.permission = {...this.dataPermission}
      },
      deep: true
    }
  },
  data () {
    return {
      baseService: new BaseServices(this),
      form: {},
      country: [],
      fieldsForm: [],
      buttonSend: { color: 'success', title: 'Guardar', text: 'Guardar'},
      containerButtonsClass: 'col-12 text-right pt-1',
      permission: {
        create: false,
        edit: false,
        delete: false
      },
      titleModal: 'Crear respuesta predeterminada',
      optionDelivery:[
        {
          id: '',
          text: 'Todos'
        },
        {
          id: 'delivery',
          text: 'Envíos'
        },
        {
          id: 'return',
          text: 'Devoluciones'
        }
      ]
    }
  },
  methods: {
    close() {
      // this.dataEdit = null
      this.$bvModal.hide('modalNesAnswer')
    },
    createAnswer(dataSave) {
      const params = {
        shipper_id: this.$session.get('cas_user').id ? this.$session.get('cas_user').id : ''
      }
      const queryParams = this.setQueryParams(dataSave)
      this.baseService.callService('createAnswer', queryParams, params)
        .then(response => {
          this.$emit('update:updateData', response.data)
          this.close()
        })
        .catch(err => {
          this.$alert(this.$t('msg-problema-actualizar', {code: err}))
        })
    },
    editAnswer(dataSave) {
      const params = {
        shipper_id: this.$session.get('cas_user').id ? this.$session.get('cas_user').id : '',
        id: this.dataEdit.id
      }
      const queryParams = this.setQueryParams(dataSave)
      this.baseService.callService('updateAnswer', queryParams, params)
        .then(response => {
          this.$emit('update:updateData', response.data)
          this.close()
        })
        .catch(err => {
          this.$alert(this.$t('msg-problema-actualizar', {code: err}))
        })
    },
    setQueryParams(dataSave) {
      return {
        name: dataSave.name ?? '',
        code_name: dataSave.code_name ?? '',
        country: dataSave.country?.code ?? dataSave.country ?? '',
        message: dataSave.message ?? '',
        delivery_type: dataSave.delivery_type?.id ?? ''
      }
    },
    setFields() {
      this.fieldsForm = [
        { fieldType: 'FieldInput', name: 'name', label: 'Nombre', containerClass: 'col-9', validation: 'required'},
        { fieldType: 'FieldInput', name: 'code_name', label: 'Código', containerClass: 'col-3', validation: 'required'},
        { fieldType: 'FieldSelect', name: 'country', label: 'País', containerClass: 'col-12', options: this.country, validation: 'required'},
        { fieldType: 'FieldSelect', name: 'delivery_type', label: 'Tipo de envío', containerClass: 'col-12', options: this.optionDelivery},
        { fieldType: 'FieldTextarea', name: 'message', label: 'Mensaje', containerClass: 'col-12', validation: 'required'}
      ]
    },
    createEditAnswer(dataSave) {
      if (this.dataEdit && this.dataEdit.id) {
        if (this.permission.edit) {
          this.editAnswer(dataSave)
        } else {
          this.$alert('No tienes permiso para editar una regla')
        }
      } else if (this.permission.create) {
        this.createAnswer(dataSave)
      }
      else {
        this.$alert('No tienes permiso para crear una regla')
      }
    },
    showModal() {
      this.setFields()
    }, 
    setValuesEdit() {
      this.form = {...this.dataEdit}
      this.titleModal = 'Editar respuesta predeterminada'
      this.form.delivery_type = !!this.dataEdit.delivery_type || this.dataEdit.delivery_type === '' ? this.optionDelivery.find((element) => element.id === this.dataEdit.delivery_type) : {
        id: '',
        text: 'Todos'
      }
      this.form.country = this.country.find((element) => element.code === this.dataEdit.country)
    }
  }, 
  mounted() {
  }
}
</script>

<style>

</style>template
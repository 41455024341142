<template>
  <div>
    <b-modal id="modalDeleteAnswer"
            size="lg"
            title="¿Estas seguro que quieres eliminar la siguiente regla? "
            title-class="centered"
            centered
            @ok="ok"
            :ok-title="acceptText"
            ok-variant="danger"
            ok-only>
        <dl>
          <dt>Nombre</dt>
          <dd>{{dataAnswer.name}}</dd>
          <dt>Código</dt>
          <dd>{{dataAnswer.code_name ? dataAnswer.code_name : 'Indefinido'}}</dd>
          <dt>País</dt>
          <dd>{{dataAnswer.country ? dataAnswer.country : 'Indefinido'}}</dd>
          <dt>Mensaje</dt>
          <dd>{{dataAnswer.message ? dataAnswer.message : 'Sin mensaje'}}</dd>
        </dl>
    </b-modal>
  </div>
</template>

<script>
import { mounted } from 'vue-echarts'
import BaseServices from '@/store/services/index'
export default {
  name: 'modal-delete-answer',
  props: {
    dataDelete: {},
    deleteAnswer: {}
  },
  watch: {
    dataDelete: {
      handler () {
        this.dataAnswer = {...this.dataDelete}
      },
      deep: true
    }
  },
  data () {
    return {
      baseService: new BaseServices(this),
      dataAnswer: {},
      acceptText: 'Eliminar'
    }
  },
  methods: {
    close() {
      this.$bvModal.hide('modalDeleteAnswer')
    },
    deleteAnswerById() {
      const params = {
        shipper_id: this.$session.get('cas_user').id ? this.$session.get('cas_user').id : '',
        id: this.dataDelete.id
      }
      const queryParams = {
      }
      this.baseService.callService('deleteAnswer', queryParams, params)
        .then(response => {
          this.$emit('update:deleteAnswer', true)
          this.close()
        })
        .catch(err => {
          this.$alert(this.$t('msg-problema-eliminar-elemento', {code: err}))
        })
    },
    ok () {
      this.deleteAnswerById()
    }
  }
}
</script>

<style>

</style>template